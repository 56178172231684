import { ReactNode } from "react";
import { ConfigProvider as AntdConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

import { createTheme } from "@styles/theme";

const neutralPrimaryColor = "#9a9a9a";
const theme = createTheme({
  theme: { token: { colorPrimary: neutralPrimaryColor } },
} as ClientSettings);

const cssVariables = `
:root {
--theme-primary-color: ${theme.token?.colorPrimary};
}
`;

export const NeutralTheme = ({ children }: { children?: ReactNode }) => {
  return (
    <>
      <style>{cssVariables}</style>
      <AntdConfigProvider locale={ptBR} theme={theme}>
        {children}
      </AntdConfigProvider>
    </>
  );
};
