import { useMutation } from "@tanstack/react-query";
import { getMutationFnHappy } from "@api/tools/happy";
import { getImageUrl } from "@utils/file";

const getTheme = getMutationFnHappy<
  IApiClientThemeResponse,
  IApiClientThemeData
>("/auth/corban/correspondente/");

export const useFetchClientSettings = () => {
  const {
    mutateAsync: fetchTheme,
    data,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: getTheme,
    onSuccess: async (data) => {
      // Preload main images to prevent fickering
      await Promise.allSettled([
        getImageUrl(data.formalizacao.logo),
        getImageUrl(data.formalizacao.favicon),
        getImageUrl(data.formalizacao_cartao.logo),
        getImageUrl(data.formalizacao_cartao.favicon),
      ]);
    },
  });

  return [fetchTheme, { data, loading, error }] as const;
};
