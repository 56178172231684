import { ReactNode, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseLayout } from "@components/BaseLayout";
import { PRODUCT_TYPE } from "@utils/enums";
import { useClientSettingsContext } from "@hooks/useClientSettings";
import { Helmet } from "react-helmet-async";
import { NeutralTheme } from "@styles/NeutralTheme";
import { featureFlags } from "@settings";

const CARD_PRODUCT_TYPES = [
  PRODUCT_TYPE.CARTAO_BENEFICIO,
  PRODUCT_TYPE.CARTAO_BENEFICIO_REPRESENTANTE,
  PRODUCT_TYPE.CARTAO_CONSIGNADO,
  PRODUCT_TYPE.SAQUE_COMPLEMENTAR,
];

const parseProductType = (
  productType?: null | string | number | PRODUCT_TYPE,
): PRODUCT_TYPE | null => {
  if (!productType) return null;

  const _productType =
    typeof productType === "string" ? parseInt(productType) : productType;

  if (
    !_productType ||
    !PRODUCT_TYPE[productType as keyof typeof PRODUCT_TYPE]
  ) {
    return null;
  }

  return _productType as PRODUCT_TYPE;
};

/**
 * Sets the current ClientSettings and only renders the children when a type is valid.
 *
 * If a type is provided it will be used
 * If a error is provided, the 'default' theme will be applied
 * If a contract is provided and no error is provided, the theme type will be defined based on the contract product type
 * if there is a valid PRODUCT_TYPE present in the the search param "product", the theme type will be defined based on the query param
 */
export const ClientTheme = ({
  children,
  type: themeType,
  error,
  contract,
}: {
  children: ReactNode;
  type?: ClientSettingsThemeType | null;
  error?: boolean;
  contract?: Contrato | null;
}) => {
  const [search] = useSearchParams();
  const searchProduct = useMemo(() => {
    const key = Array.from(search.keys()).find(
      (k) => k.toLowerCase() === "product",
    );
    return search.get(key ?? "");
  }, [search]);

  const productType = contract?.tipo_produto || searchProduct;

  const { setThemeType, themeType: ctxThemeType } = useClientSettingsContext();

  const product = useMemo(() => parseProductType(productType), [productType]);

  const type = useMemo(() => {
    if (themeType) return themeType;

    if (product) {
      if (CARD_PRODUCT_TYPES.includes(product)) {
        return "cartao";
      } else {
        return "default";
      }
    }

    if (error) return "default";

    return null;
  }, [product, error, themeType]);

  useEffect(() => {
    if (!featureFlags.enableCorrespondenteBanqueiro) return;
    if (type && ["default", "cartao"].includes(type)) setThemeType(type);
  }, [type, setThemeType]);

  if (!featureFlags.enableCorrespondenteBanqueiro) {
    return <>{children}</>;
  }

  const isTypeSettled = type === ctxThemeType;

  if (!isTypeSettled) {
    return (
      <>
        {product ? (
          <BaseLayout isLoading />
        ) : (
          <NeutralTheme>
            <Helmet>
              <link rel="icon" type="image/svg+xml" href="" />
              <title />
            </Helmet>
            <BaseLayout hideHeader isLoading />
          </NeutralTheme>
        )}
      </>
    );
  }

  return <>{children}</>;
};
