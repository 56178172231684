import React, { ReactNode } from "react";
import { NewRelicProvider } from "./providers/NewRelicProvider";
import { SmartlookProvider } from "./providers/SmartlookProvider";
import { ClarityProvider } from "./providers/ClarityProvider";

/**
 * Groups providers from third party monitoring services.
 *
 * Renders providers for:
 * - New Relic
 * - Clarity
 * - Smartlook
 */
export const MonitoringProvider = ({ children }: { children: ReactNode }) => (
  <NewRelicProvider>
    <SmartlookProvider>
      <ClarityProvider>{children}</ClarityProvider>
    </SmartlookProvider>
  </NewRelicProvider>
);
