import React, { ReactNode, useEffect } from "react";
import { isProduction } from "@settings";
import { useClientApiKeys } from "@hooks/useClientSettings";

export const ClarityProvider = ({ children }: { children: ReactNode }) => {
  const {
    clarity: { clientKey },
  } = useClientApiKeys();

  useEffect(() => {
    if (!clientKey || !isProduction) return;
    const init = async () => {
      const { clarity } = await import("react-microsoft-clarity");
      clarity.init(clientKey);
    };

    void init();
  }, [clientKey]);

  return <>{children}</>;
};
