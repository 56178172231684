import { ComponentProps } from "react";
import { useQuery } from "@tanstack/react-query";
import { getImageUrl } from "@utils/file";

export const ImgIfExists = ({ src, ...props }: ComponentProps<"img">) => {
  const { isFetching, isSuccess } = useQuery({
    queryFn: () => (src ? getImageUrl(src) : {}),
    queryKey: [src],
  });
  const url = !isFetching && isSuccess && src;

  if (!url) return null;

  return <img {...props} src={url} />;
};
