import {
  ComponentProps,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RxChevronDown } from "react-icons/rx";
import { tv } from "tailwind-variants";
import { debounce } from "lodash";
import { Button } from "../Base";

type ButtonScrollToBottomProps = ComponentProps<typeof Button> & {
  scrollRef: RefObject<HTMLElement>;
};

const button = tv({
  base: "fixed bottom-2 right-2 translate-y-20 drop-shadow-lg",
  variants: {
    visible: {
      true: "translate-y-0",
    },
  },
  defaultVariants: {
    visible: false,
  },
});

export const ButtonScrollToBottom = ({
  className,
  scrollRef,
  ...props
}: ButtonScrollToBottomProps) => {
  const [visible, setVisible] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const scrollToBottom = () => {
    const elementRef = scrollRef.current;
    elementRef?.scrollTo({
      top: elementRef.scrollHeight,
      behavior: "smooth",
    });
  };

  const updateScrollbarWidth = useCallback(() => {
    const scrollElement = scrollRef.current;
    const buttonElement = buttonRef.current;

    if (scrollElement && buttonElement) {
      const scrollbarWidth =
        scrollElement.offsetWidth - scrollElement.clientWidth;

      buttonElement.style.marginRight = `${scrollbarWidth}px`;
    }
  }, [scrollRef]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    const buttonElement = buttonRef.current;

    const handleButtonVisibiilty = debounce(() => {
      if (scrollElement && buttonElement) {
        const { scrollHeight, scrollTop, clientHeight } = scrollElement;
        const bottomDistance = scrollHeight - (scrollTop + clientHeight);

        if (bottomDistance > 40) {
          setVisible((isVisible) => {
            if (!isVisible) {
              updateScrollbarWidth();
            }

            return true;
          });
        } else {
          setVisible(false);
        }
      }
    }, 50);

    scrollElement?.addEventListener("scroll", handleButtonVisibiilty);

    return () => {
      scrollElement?.removeEventListener("scroll", handleButtonVisibiilty);
    };
  }, [scrollRef, updateScrollbarWidth]);

  useEffect(() => {
    window?.addEventListener("resize", updateScrollbarWidth);

    return () => {
      window?.removeEventListener("resize", updateScrollbarWidth);
    };
  }, [updateScrollbarWidth]);

  useEffect(() => {
    updateScrollbarWidth();
  }, [updateScrollbarWidth]);

  return (
    <Button
      ref={buttonRef}
      title="Ir até o fim da página"
      shape="round"
      {...props}
      onClick={scrollToBottom}
      className={button({ className, visible })}
    >
      <RxChevronDown className="text-4xl text-white" />
    </Button>
  );
};
