import { createBrowserRouter } from "react-router-dom";

import { featureFlags } from "@settings";
import { ProvidersAndConfigs } from "@components/ProvidersAndConfigs";

const routeEnvelope = () => import("@pages/Envelope");
const routeReformalizacao = () => import("@pages/Reformalizacao");
const routeRetention = () => import("@pages/Retention");

export const router = createBrowserRouter(
  [
    {
      element: <ProvidersAndConfigs />,
      children: [
        {
          path: "/",
          lazy: () => import("@pages/Home"),
        },
        {
          path: "/:token",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/envelope/:token",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/:token/rogado",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/:token/reformalizacao",
          lazy: () => routeReformalizacao(),
        },
        {
          path: "/:token/reformalizacao/rogado",
          lazy: () => routeReformalizacao(),
        },
        {
          path: "/retencao/:token",
          lazy: () => routeRetention(),
        },
        {
          path: "/in100/:cpf/:beneficio",
          lazy: () => import("@pages/In100/Portability"),
        },
        {
          path: "/autorizacao-in100/:cpf",
          lazy: () => {
            return featureFlags.enableSameIn100Accept
              ? import("@pages/In100/Portability")
              : import("@pages/In100/BenefitCard");
          },
        },
        {
          path: "/user/:uniqueId",
          lazy: () => import("@pages/User"),
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);
