import { Divider, Layout } from "antd";

import { featureFlags } from "@settings";

import { BankLogo } from "@components/BankLogo";
import { Logo } from "@components/Logo";

export const Header = ({ invisible }: { invisible?: boolean }) => {
  return (
    <Layout.Header className={`relative flex items-center bg-white shadow-sm`}>
      <div className={`flex h-9 w-24 items-center`}>
        {!invisible && (
          <>
            <Logo />

            {featureFlags.enableBankLogo && (
              <>
                <Divider type="vertical" className="mx-6 h-8" />

                <BankLogo />
              </>
            )}
          </>
        )}
      </div>
    </Layout.Header>
  );
};
