import defaultTheme from "tailwindcss/defaultTheme";

export const createTheme = (client: ClientSettings) => {
  return {
    token: {
      colorPrimary: client.theme.token?.colorPrimary,
      colorSuccess: "#28A745",
      fontFamily: `Montserrat, ${defaultTheme.fontFamily.sans.join(",")}`,
    },
  };
};
