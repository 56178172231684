import { PropsWithChildren, createContext } from "react";
import { Helmet } from "react-helmet-async";

import { useClientSettings } from "@hooks/useClientSettings";

export const HelmetContext = createContext(null);

const apiUrlV2 = localStorage.getItem("apiUrlOriginacao") === "v2";

export const HelmetContextProvider = ({ children }: PropsWithChildren) => {
  const client = useClientSettings();
  return (
    <HelmetContext.Provider value={null}>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={client.assets.favicon} />
        <title>
          {apiUrlV2 ? `v2 - ${client.name} Formalização` : client.name}{" "}
          Formalização
        </title>
      </Helmet>

      {children}
    </HelmetContext.Provider>
  );
};
