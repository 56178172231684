import * as ExifReader from "exifreader";

type ImageMetadata = {
  width: number;
  height: number;
  density: number;
  colorModel: string;
};

export const getTypeFromBase64 = (url: string) => {
  return url.split(";")[0].split(":")[1];
};

export const getFileExtensionFromBase64 = (url: string) => {
  return getTypeFromBase64(url).split("/")[1];
};

export const getFileExtensionFromName = (filename: string) => {
  return /(?:\.([^.]+))?$/.exec(filename)?.[1] || "";
};

export const base64ToBlob = async (url: string) => {
  const res = await fetch(url);
  return res.blob();
};

export const base64ToFile = async (url: string, fileName: string) => {
  const type = getTypeFromBase64(url);
  const blob = await base64ToBlob(url);
  return new File([blob], fileName, { type });
};

export const stripBase64Header = (url: string) => {
  const base64Split = url.split(",");

  if (base64Split.length > 1) {
    const header = decodeURIComponent(base64Split[0]).toLowerCase();

    if (header.startsWith("data:")) {
      return base64Split[1];
    }
  }

  return url;
};

export const fileToDataURI = (file: File | Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const getImageUrl = (name: string) => {
  return new Promise<string>((resolve, reject) => {
    const url = new URL(name, import.meta.url).href;

    const img = new Image();
    img.src = url;

    if (img.complete) {
      resolve(url);
    } else {
      img.onload = () => {
        resolve(url);
      };

      img.onerror = () => {
        reject(new Error("Error loading image"));
      };
    }
  });
};

/**
 * Calculates image density from an ArrayBuffer containing image metadata using
 * ExifReader.
 * @param {ArrayBuffer} buffer - The ArrayBuffer containing image metadata.
 * @returns The calculated image density.
 */
export const calculateImageDensity = (buffer: ArrayBuffer) => {
  const tags = ExifReader.load(buffer);

  const xResolution = Number(tags?.["XResolution"]?.description ?? 0);
  const yResolution = Number(tags?.["YResolution"]?.description ?? 0);
  const resolutionUnit = Number(tags?.["ResolutionUnit"]?.value ?? 0);

  if (xResolution === yResolution && xResolution > 0 && resolutionUnit > 0) {
    switch (resolutionUnit) {
      case 2:
        return xResolution;
      case 3:
        return xResolution / 2.54;
      default:
        return 0;
    }
  }

  const xPixelPerUnit = Number(tags?.["Pixels Per Unit X"]?.value ?? 0);
  const yPixelPerUnit = Number(tags?.["Pixels Per Unit Y"]?.value ?? 0);
  const pixelUnit = Number(tags?.["Pixel Units"]?.value ?? 0);

  if (xPixelPerUnit === yPixelPerUnit && xPixelPerUnit > 0 && pixelUnit > 0) {
    switch (pixelUnit) {
      case 1:
        return Math.ceil(xPixelPerUnit * 0.0254);
      default:
        return 0;
    }
  }

  return 0;
};

/**
 * Extracts metadata such as color model, height, width, and density from an image
 * file using Exif data and image density calculation.
 * @param {File} file - The image file.
 * @returns An object with properties `colorModel`, `density`, `height`, and
 * `width`, which represent the metadata of the image file.
 */
export const getImageMetadata = async (file: File): Promise<ImageMetadata> => {
  const buffer = await file.arrayBuffer();
  const tags = ExifReader.load(buffer);

  const colorModel =
    tags["Color Type"]?.description?.trim() ||
    tags["Color Space"]?.description?.trim();
  const height = Number(tags["Image Height"]?.value) || 0;
  const width = Number(tags["Image Width"]?.value) || 0;
  const density = calculateImageDensity(buffer);

  return { colorModel, density, height, width };
};
