import { Spin } from "antd";

import { Warning } from "..";

type WarningLoadingProps = {
  message?: string;
};

export const WarningLoading = ({ message }: WarningLoadingProps) => {
  return <Warning icon={<Spin size="large" />} message={message} />;
};
