import { ClientSettingsContext } from "@contexts/ClientSettings";
import { useContext } from "react";

export const useClientSettingsContext = () => useContext(ClientSettingsContext);

export const useSetClientTheme = () => useClientSettingsContext().setThemeType;

export const useClientSettings = () => useClientSettingsContext().client;

export const useClientApiKeys = () => useClientSettings().apiKeys;
