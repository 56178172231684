import { ReactNode, useMemo } from "react";
import { ConfigProvider as AntdConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

import { createTheme } from "@styles/theme";
import { useClientSettings } from "@hooks/useClientSettings";

export const StyleProvider = ({ children }: { children?: ReactNode }) => {
  const client = useClientSettings();
  const theme = useMemo(() => createTheme(client), [client]);
  const cssVariables = `
:root {
  --theme-primary-color: ${theme.token?.colorPrimary};
}
`;

  return (
    <>
      <style>{cssVariables}</style>
      <AntdConfigProvider locale={ptBR} theme={theme}>
        {children}
      </AntdConfigProvider>
    </>
  );
};
