import { useRef } from "react";
import { Col, Layout, Row, Spin } from "antd";
import { ToastContainer } from "react-toastify";

import { Header } from "@components/Header";
import { WarningError } from "@components/Warning/Error";
import { WarningSuccess } from "@components/Warning/Success";
import { WarningLoading } from "@components/Warning/Loading";
import { ButtonScrollToBottom } from "@components/Buttons/ScrollToBottom";

type BaseLayoutProps = React.PropsWithChildren<{
  isLoading?: boolean;
  hideHeader?: boolean;
  loadingMessage?: string;
  error?: string | null;
  success?: string | null;
}>;

export const BaseLayout = ({
  isLoading,
  hideHeader,
  loadingMessage,
  error,
  success,
  children,
}: BaseLayoutProps) => {
  const layoutContentRef = useRef<HTMLElement>(null);

  return (
    <>
      <Layout className="h-full">
        <Header invisible={hideHeader} />

        <Layout.Content
          ref={layoutContentRef}
          className="relative h-full overflow-auto bg-zinc-100 py-7 pl-2 pr-3"
        >
          {error && <WarningError message={error} />}

          {success && <WarningSuccess message={success} />}

          {isLoading && loadingMessage && (
            <WarningLoading message={loadingMessage} />
          )}

          {!error && !success && (
            <>
              {isLoading && (
                <div className="flex h-full items-center justify-center">
                  <Spin size="large" />
                </div>
              )}

              {!isLoading && (
                <Row justify="center">
                  <Col xs={24} md={20} lg={14} xl={12}>
                    <div className="flex flex-col gap-6">{children}</div>
                  </Col>
                </Row>
              )}
            </>
          )}

          <ButtonScrollToBottom scrollRef={layoutContentRef} />
        </Layout.Content>
      </Layout>

      <ToastContainer />
    </>
  );
};
