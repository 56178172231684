type WarningProps = {
  icon?: JSX.Element;
  message?: string;
};

export const Warning = ({ icon, message }: WarningProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2 text-center text-xl text-neutral-600">
      {icon}
      {message}
    </div>
  );
};
