import { AiOutlineCheckCircle } from "react-icons/ai";

import { Warning } from "..";

type WarningSuccessProps = {
  message?: string;
};

export const WarningSuccess = ({ message }: WarningSuccessProps) => {
  return (
    <Warning
      icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
      message={message}
    />
  );
};
