import { getQueryFn, getMutationFn } from "@api/tools/base";
import { apiUrlOriginacao } from "@settings";

const BASE_URL = apiUrlOriginacao;

export const queryFnHappy = getQueryFn(BASE_URL);

export const getMutationFnHappy = <TData = unknown, TVariables = unknown>(
  action?: Parameters<typeof getMutationFn>[1],
  method?: Parameters<typeof getMutationFn>[2],
  options: Parameters<typeof getMutationFn>[3] = {},
) => getMutationFn<TData, TVariables>(BASE_URL, action, method, options);
