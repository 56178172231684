import { ComponentProps, forwardRef } from "react";
import { IconType } from "react-icons";
import { AiOutlineLoading } from "react-icons/ai";
import { tv, VariantProps } from "tailwind-variants";

type ButtonProps = Omit<ComponentProps<"button">, "ref"> & {
  loading?: boolean;
  icon?: IconType;
  iconSize?: number;
};

const button = tv({
  base: "flex items-center justify-center gap-3 bg-primary px-5 py-3 text-base tracking-wide text-white transition duration-200 hover:brightness-125 focus:brightness-95 active:brightness-90 disabled:pointer-events-none disabled:opacity-25",
  variants: {
    shape: {
      default: "rounded-md",
      round: "rounded-full p-3",
    },
  },
  defaultVariants: {
    shape: "default",
  },
});

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & VariantProps<typeof button>
>(
  (
    {
      className,
      shape,
      loading,
      icon: Icon,
      iconSize,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        {...props}
        disabled={disabled || loading}
        className={button({ className, shape })}
      >
        {loading && <AiOutlineLoading className="animate-spin" />}
        {Icon && <Icon size={iconSize || 20} />}
        <span>{children}</span>
      </button>
    );
  },
);

Button.displayName = "Button";
