import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

import { appVersion, isProduction } from "@settings";
import { useClientApiKeys } from "@hooks/useClientSettings";

type NewRelicContext = {
  browserAgent: BrowserAgent | undefined;
};

export const NewRelicContext = createContext<NewRelicContext>({
  browserAgent: undefined,
});

export const NewRelicProvider = ({ children }: PropsWithChildren) => {
  const { newRelic } = useClientApiKeys();
  const [browserAgent, setBrowserAgent] = useState<BrowserAgent | undefined>();

  const contextState = useMemo(() => ({ browserAgent }), [browserAgent]);

  useEffect(() => {
    async function setupBrowserAgent() {
      const { BrowserAgent } = await import(
        "@newrelic/browser-agent/loaders/browser-agent"
      );

      const options = {
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ["bam.nr-data.net"] },
        },
        info: {
          beacon: "bam.nr-data.net",
          errorBeacon: "bam.nr-data.net",
          licenseKey: newRelic.licenceKey,
          applicationID: newRelic.applicationId,
          sa: 1,
          jsAttributes: { "application.version": appVersion },
        },
        loader_config: {
          accountID: newRelic.accountId,
          trustKey: newRelic.accountId,
          agentID: newRelic.applicationId,
          licenseKey: newRelic.licenceKey,
          applicationID: newRelic.applicationId,
        },
      };

      const browserAgent = new BrowserAgent(options);
      setBrowserAgent(browserAgent);
    }

    if (isProduction && newRelic.applicationId) {
      void setupBrowserAgent();
    }
  }, [newRelic]);

  return (
    <NewRelicContext.Provider value={contextState}>
      {children}
    </NewRelicContext.Provider>
  );
};

export const useNewRelic = () => {
  return useContext(NewRelicContext);
};
