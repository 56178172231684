import { ImgIfExists } from "@components/ImgIfExists";
import { useClientSettings } from "@hooks/useClientSettings";

export const BankLogo = () => {
  const client = useClientSettings();

  return (
    <ImgIfExists
      alt="Logo do banco"
      className="max-h-full max-w-full"
      src={client.assets.bankLogo}
    />
  );
};
