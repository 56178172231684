import { BaseLayout } from "@components/BaseLayout";
import { useFetchClientSettings } from "@hooks/useFetchClientSettings";
import { mapClientThemeSettings } from "@mappers/client-theme";
import {
  featureFlags,
  clientSettings as staticClientSettings,
} from "@settings";
import { NeutralTheme } from "@styles/NeutralTheme";
import { isCardExclusiveEnv } from "@utils/cardExclusiveEnv";
import { defaultsDeep } from "lodash";
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";

export type ClientSettingsState = {
  client: ClientSettingsWithKeys;
  setThemeType: (type: ClientSettingsThemeType) => void;
  themeType: ClientSettingsThemeType;
};

export const ClientSettingsContext = createContext<ClientSettingsState>({
  client: null!,
  setThemeType: () => {},
  themeType: "default",
});

const staticCardClientSettings = defaultsDeep(
  {},
  staticClientSettings?.cardExclusiveTheme ?? {},
  staticClientSettings,
) as ClientSettingsWithKeys;

export const ClientSettingsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [fetchTheme, { data, loading, error }] = useFetchClientSettings();

  const [themeType, setThemeType] = useState<"default" | "cartao">(
    featureFlags.enableCorrespondenteBanqueiro
      ? "default"
      : isCardExclusiveEnv()
        ? "cartao"
        : "default",
  );
  const [isStaticTheme, setIsStaticTheme] = useState(
    !featureFlags.enableCorrespondenteBanqueiro,
  );

  useEffect(() => {
    if (data || loading || error || isStaticTheme) return;
    const fetch = async () => {
      try {
        await fetchTheme({ origin: location.origin });
      } catch (e) {
        setIsStaticTheme(true);
        throw new Error("Ocorreu um erro ao recuperar o tema do cliente");
      }
    };

    void fetch();
  }, [fetchTheme, data, loading, error, isStaticTheme]);

  const client = useMemo(() => {
    if (isStaticTheme) {
      const isCardTheme = featureFlags.enableCorrespondenteBanqueiro
        ? themeType === "cartao"
        : isCardExclusiveEnv();

      return isCardTheme ? staticCardClientSettings : staticClientSettings;
    }
    if (!data) return null;

    return mapClientThemeSettings(data, themeType);
  }, [data, isStaticTheme, themeType]);

  const contextState = useMemo<ClientSettingsState>(
    () => ({ client: client!, setThemeType, themeType }),
    [client, setThemeType, themeType],
  );

  if (!isStaticTheme && error) return "Serviço temporariamente indisponível";
  if (loading || !client)
    return (
      <NeutralTheme>
        <BaseLayout hideHeader isLoading />
      </NeutralTheme>
    );

  return (
    <ClientSettingsContext.Provider value={contextState}>
      {children}
    </ClientSettingsContext.Provider>
  );
};
