import { AiOutlineWarning } from "react-icons/ai";

import { Warning } from "..";

type WarningErrorProps = {
  message?: string;
};

export const WarningError = ({ message }: WarningErrorProps) => {
  return (
    <Warning
      icon={<AiOutlineWarning className="text-9xl text-red-500" />}
      message={message}
    />
  );
};
