import { ImgIfExists } from "@components/ImgIfExists";
import { useClientSettings } from "@hooks/useClientSettings";

export const Logo = () => {
  const client = useClientSettings();

  return (
    <ImgIfExists
      alt="Logo"
      className="max-h-full max-w-full"
      src={client.assets.logo}
    />
  );
};
