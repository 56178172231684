import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";

import { queryFnHappy } from "@api/tools/happy";

import "@styles/index.css";
import { StyleProvider } from "@styles/StyleProvider";
import { HelmetContextProvider } from "@contexts/HelmetContext";
import { ClientSettingsProvider } from "@contexts/ClientSettings";
import { MonitoringProvider } from "@contexts/Monitoring";

const queryClient = new QueryClient({
  defaultOptions: { queries: { queryFn: queryFnHappy } },
});

export const ProvidersAndConfigs = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ClientSettingsProvider>
          <MonitoringProvider>
            <HelmetContextProvider>
              <StyleProvider>
                <Outlet />
              </StyleProvider>
            </HelmetContextProvider>
          </MonitoringProvider>
        </ClientSettingsProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
