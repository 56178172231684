import React, { ReactNode, useEffect } from "react";
import { envMode, isProduction } from "@settings";
import { useClientApiKeys } from "@hooks/useClientSettings";

export const SmartlookProvider = ({ children }: { children: ReactNode }) => {
  const {
    smartlook: { clientKey },
  } = useClientApiKeys();

  useEffect(() => {
    if (!clientKey || !isProduction) return;
    const init = async () => {
      const { default: Smartlook } = await import("smartlook-client");

      Smartlook.init(clientKey, {
        region: "eu",
      });
      Smartlook.properties({ environment: envMode });
    };

    void init();
  }, [clientKey]);

  return <>{children}</>;
};
