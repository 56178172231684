import { env } from "./env";

/**
 * Parses a feature flag key located in the environment variables
 * @returns Whether the feature flag is active
 */
export function flag(key: keyof ImportMetaEnv): boolean {
  return env[key] === "1";
}
/**
 * Feature flags
 */
export const featureFlags = {
  enableBankLogo: flag("VITE_ENABLE_BANK_LOGO"),
  enableCorrespondenteBanqueiro: flag("VITE_ENABLE_CORRESPONDENTE_BANQUEIRO"),
  enableSameIn100Accept: flag("VITE_ENABLE_SAME_IN100_ACCEPT"),
  enableImageDocumentsRequirementsToUpload: flag(
    "VITE_ENABLE_IMAGE_DOCUMENTS_REQUIREMENTS_TO_UPLOAD",
  ),
} as const;
